<mat-toolbar class="toolbar">

  <img
    src="assets\images\rain-logo.png"
    class="rain-logo"
    alt="rain logo"
    (click)="onHome()"
    [matTooltip]="homeToolTipMessage"
  >

  <p class="version">{{version}}</p>



  <!-- <mat-icon class="menu-icon text-blue" (click)="toggleSidenav()">menu</mat-icon> -->
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <span class="default-spacer"></span>
  <custom-icon-button
    (click)="toggleLetsDial()"
    [active]="showDialler$ | async"
  >
  phone_iphone
  </custom-icon-button>
  <sf-agent-voice-registered-icon></sf-agent-voice-registered-icon>
  <sf-refresh-smartsub-data-button *ngIf="isOnCustomerInfoPage"></sf-refresh-smartsub-data-button>
  <sf-agent-ticket-toggle></sf-agent-ticket-toggle>
  <app-agent-phone-available-modal></app-agent-phone-available-modal>
  <app-agent-status-modal></app-agent-status-modal>
  <sf-websocket-error></sf-websocket-error>
  <custom-icon-button
    (click)="togglePhoneModal()"
    [active]="phoneModalActive"
  >phone</custom-icon-button>
  <sf-voice-queue-error></sf-voice-queue-error>

  <sf-agent-notifications></sf-agent-notifications>

  <p
    class="sign-out text-grey"
    (click)="onSignOut()"
  >sign out</p>
  <span class="divider text-grey">|</span>
  <app-profile></app-profile>
</mat-toolbar>


<app-sip-phone (modalChanged)="setPhoneModalActive($event)"></app-sip-phone>
<!-- <sf-lets-dial></sf-lets-dial> -->
<sf-lets-dial [class.hide-lets-dial]="!(showDialler$ | async)"></sf-lets-dial>
