<ng-container *ngIf="viewModel$ | async as viewModel">
  <div
    class="lets-dial-container"
    cdkDrag
  >

    <ng-container *ngIf="authenticated;else notAuthenticated">

      <div class="settings">
        <mat-icon (click)="showSettings()">
          settings
        </mat-icon>

        <sf-select
        *ngIf="viewModel.showSettings"
        label="set availability"
        [options]="reasonTypes"
        placeholder="select reason"
        (onOptionSelect)="onOptionSelect($event)"
      >
      </sf-select>
      </div>

      <audio id="audio-remote"></audio>

      <h2>
        {{phoneStatus}}
      </h2>

      <form [formGroup]="diallerForm">
        <input
          formControlName="number"
          type="number"
        >
      </form>



      <div class="key-pad">
        <ng-container *ngFor="let key of viewModel?.keyPadKeys">

          <button
            class="key"
            (click)="onKeyPad(key)"
          >
            {{key}}
          </button>

        </ng-container>

      </div>

      <div class="bottom-btns">

        <button
          *ngIf="phoneStatus !== 'INITIATING' && phoneStatus !== 'RINGING' && phoneStatus !== 'ON_CALL' && phoneStatus !== 'ANSWERED' "
          class="call-btn make-call-btn"
          (click)="makeCall()"
        >
          <!-- [disabled]="diallerForm.invalid" -->
          <mat-icon>
            phone
          </mat-icon>

        </button>

        <button
          *ngIf="phoneStatus === 'INITIATING' || phoneStatus === 'RINGING' || phoneStatus === 'ON_CALL' || phoneStatus === 'ANSWERED' "
          class="call-btn end-call-btn"
          (click)="endCall()"
        >
          <mat-icon>
            call_end
          </mat-icon>

        </button>


        <mat-icon
          class="back-btn"
          (click)="backspace()"
        >
          backspace
        </mat-icon>

      </div>

    </ng-container>

    <ng-template #notAuthenticated>

      <form [formGroup]="authForm">
        <sf-form-input
          [control]="authForm.get('username')"
          label="username"
          placeholder="example@rain.co.za"
        ></sf-form-input>

        <sf-form-input
          [control]="authForm.get('password')"
          label="password"
          placeholder="letitrain"
        ></sf-form-input>
      </form>

      <div class="error">
        <p>
          {{pluginError}}
        </p>
      </div>

      <sf-rain-button (click)="onSignIn()">
        sign in
      </sf-rain-button>

    </ng-template>


  </div>
</ng-container>
