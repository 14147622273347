import { PaymentDateOption } from "../../store/types/payment-date-options.interface";


export const RAIN_ONE_BASE_PRICE = 559;

export const RAIN_ONE_101_BASE_PRICE = 625;

export const RAIN_MOBILE_BASE_PRICE = 165;
export const RAIN_MOBILE_THREE_PACK_PRICE = 135;
export const RAIN_MOBILE_FIVE_PACK_PRICE = 115;

export const getDefaultSalesProductOptions = (): { id: string; descriptions: string[]; image: string; type: string; }[] =>
    [
        {
            id: '1',
            descriptions: ['Unlimited 5G home wifi', 'Speeds up to 30Mbps', 'Free-to-use router'],
            image: 'assets/images/rain-one/cpe-only@2x.png',
            type: 'device'
        },
        {
            id: '2',
            descriptions: ['every month:', '2 FREE gigs', '60 FREE minutes', '100 FREE sms'],
            image: 'assets/images/rain-one/fourg-sim-only.png',
            type: 'sim'
        },
        {
            id: '3',
            descriptions: ['every month:', '2 FREE gigs', '60 FREE minutes', '100 FREE sms'],
            image: 'assets/images/rain-one/fourg-sim-only.png',
            type: 'sim'
        }
    ];

export const PAYMENT_DATE_OPTIONS: Readonly<Readonly<PaymentDateOption>[]> = [
    {
        id: "f24e74eb-1fca-4251-af49-5c8416f7456b",
        day: 1,
        displayName: "1st of month"
    } as const,
    {
        id: "6cf4ed53-4090-416c-9060-7afd658da286",
        day: 15,
        displayName: "15th of month"
    } as const,
    {
        id: "c3acc6bc-cc75-4d24-98e8-900b17befc21",
        day: 20,
        displayName: "20th of month"
    } as const,
    {
        id: "4f45f0f0-2988-4022-b0c7-974bc331453c",
        day: 25,
        displayName: "25th of month"
    } as const
];
