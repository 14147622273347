import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { APP_NAME, CUSTOMER_INFO_SUMMARY_ROUTE } from '../constants';
import { NavigationState } from '../core/store/state/navigations.state';
import { ContinueFromTicketState } from '../interactions/store/state/continue-from-ticket.state';
import { NavigationService } from '../navigation/navigation.service';
import { CustomRouterEventHandler } from '../shared/services/router-event-controller/custom-router-event-handler.interface';
import { RouterEventControllerService } from '../shared/services/router-event-controller/router-event-controller.service';
import { SipPhoneComponent } from '../sip-phone/sip-phone.component';
import { AgentActions } from '../shared/components/agent/store/actions/agent-action-actions';
import { LetsDialDefaultActions } from '../lets-dial/store/actions/lets dial default actions';
import { LetsDialDefaultState } from '../lets-dial/store/states/lets-dial-defaults.state';


const DEFAULT_HOME_MESSAGE = "Home";
const CANT_GO_HOME_MESSAGE = "Please close or update the current ticket before moving on.";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy, CustomRouterEventHandler {
  @Select(ContinueFromTicketState.canContinue) canContinue$: Observable<boolean>;
  @Select(LetsDialDefaultState.showDialler) showDialler$: Observable<boolean>;

  @ViewChild(SipPhoneComponent) sipPhone: SipPhoneComponent;

  appName = APP_NAME;
  phoneModalActive = false;
  homeToolTipMessage = DEFAULT_HOME_MESSAGE;
  canGoHome = true;
  isOnCustomerInfoPage = false;

  version = `v${environment.version}`;

  private ngDestroy$ = new Subject<void>();

  constructor(private router: Router,
    private navService: NavigationService,
    private authSerivce: AuthService,
    private store: Store,
    private routerEventService: RouterEventControllerService) {
    this.routerEventService.addCustomEventHandler(this);
  }


  ngOnInit(): void {
    this.checkNavigationState();

    this.canContinue$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => this.checkNavigationState());
  }

  setPhoneModalActive(open: boolean) {
    this.phoneModalActive = open;
  }

  toggleSidenav() {
    this.navService.toggleSidenav()
  }

  onHome() {
    if (this.canGoHome) {
      this.router.navigate(["/home"]);
      this.store.dispatch(new AgentActions.HomeButtonClicked());
    }
  }

  onSignOut() {
    this.authSerivce.logout();
  }

  togglePhoneModal() {
    this.sipPhone.toggleModalOpen();
  }

  toggleLetsDial(){
    const showDialler = this.store.selectSnapshot(LetsDialDefaultState.showDialler)
    this.store.dispatch(new LetsDialDefaultActions.ShowDialler(!showDialler))
  }

  checkNavigationState() {
    const canContinue = this.store.selectSnapshot(ContinueFromTicketState.canContinue);
    const sentFromAgentView = this.store.selectSnapshot(NavigationState.getSentFromAgentViewStatus);
    const inSearchPage = this.router.url.includes("search");

    this.canGoHome = (!sentFromAgentView || canContinue || inSearchPage);
    this.homeToolTipMessage = this.canGoHome ? DEFAULT_HOME_MESSAGE : CANT_GO_HOME_MESSAGE;
  }

  handleRouteChange(router: Router): void {
    this.isOnCustomerInfoPage = router.url.includes(CUSTOMER_INFO_SUMMARY_ROUTE);
    this.checkNavigationState();
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}
