import { Utils } from "src/app/Utils";

export class ShowDialler {
  static readonly type = Utils.Helpers.Type('[ Lets Dial Default Actions ] open dialler')
  constructor(public showDialler: boolean) { }
}

export class ShowSettings {
  static readonly type = Utils.Helpers.Type('[ Lets Dial Default Actions ] open settings')
  constructor(public showSettings: boolean) { }
}
export class GenerateKeyPadKeys {
  static readonly type = Utils.Helpers.Type('[ Lets Dial Default Actions ] generate keys')
}
