import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LetsDialComponent } from './lets-dial.component';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { LetsDialDefaultState } from './store/states/lets-dial-defaults.state';

const declarations = [
  LetsDialComponent,
]

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 5000000,
};

@NgModule({
  declarations: [
    ...declarations
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxsModule.forFeature([
      LetsDialDefaultState
  ])
  ],
  exports: [
    ...declarations
  ],
  providers: [
    {
      provide: CDK_DRAG_CONFIG,
      useValue: DragConfig
    }
  ]
})
export class LetsDialModule { }
