import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxPaginationModule } from 'ngx-pagination';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { MatSliderModule } from '@angular/material/slider';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import {
    MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG,
    MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent
} from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AUTHORITY, CLIENT_ID } from './constants';
import { HomeComponent } from './home/home.component';
import { AuthenticationComponent } from './auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SipPhoneComponent } from './sip-phone/sip-phone.component';
import { ProfileComponent } from './profile/profile.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CallTimerComponent } from './sip-phone/call-timer/call-timer.component';
import { OutgoingCallModalComponent } from './sip-phone/outgoing-call-modal/outgoing-call-modal.component';
import { IncomingCallModalComponent } from './sip-phone/incoming-call-modal/incoming-call-modal.component';
import { ModalWindowComponent } from './shared/modal-window/modal-window.component';
import { SharedModule } from './shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core/core.module';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { SnowFlakeRouterStateSerializer } from './core/serializers/router.serializer';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from './shared/elements/rain-forms/formatters/custom-date.format';
import { WebsocketErrorComponent } from './toolbar/websocket-error/websocket-error.component';
import { ProfileSideNavComponent } from './profile/profile-side-nav/profile-side-nav.component';
import { SipPhoneModule } from './sip-phone/sip-phone.module';
import { WarningsModule } from './warning-modals/warnings.module';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { ProfileModule } from './profile/profile.module';
import { LoadsheddingModule } from './shared/components/loadshedding/loadshedding.module';
import { MatButtonModule } from '@angular/material/button';
import { MainInterceptor } from './core/interceptors/main.interceptor';
import { MainModalsContainerComponent } from './main-modals-container/main-modals-container.component';
import { MainModalModule } from './main-modals-container/main-modal.module';
import { CustomErrorHandlerService } from './core/handlers/custom-error-handler.service';
import { ToolbarModule } from './toolbar/toolbar.module';
import { DecimalPipe } from '@angular/common';
import { Postfix } from './shared/pipes/general-pipes/postfix-pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FIREBASE_CONFIG } from 'src/environments/env.constants';
import { MaintenanceOverlayModule } from './maintenance-overlay/maintenance-overlay.module';
import { AgentControlModule } from './agent-control/agent-control.module';
import { LetsDialModule } from './lets-dial/lets-dial.module';

const DragConfig = {
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 5,
    zIndex: 50,
};

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: CLIENT_ID,
            authority: AUTHORITY,
            redirectUri: environment.redirectUri,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
        'user.read',
    ]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read'],
        },
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AuthenticationComponent,
        ToolbarComponent,
        SipPhoneComponent,
        ProfileComponent,
        BreadcrumbComponent,
        CallTimerComponent,
        OutgoingCallModalComponent,
        IncomingCallModalComponent,
        ModalWindowComponent,
        WebsocketErrorComponent,
        ProfileSideNavComponent,
        MainModalsContainerComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        MatProgressSpinnerModule,
        CdkTreeModule,
        MatButtonModule,
        FormsModule,
        MatToolbarModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        NgxPaginationModule,
        DragDropModule,
        MatSliderModule,
        ApmModule,
        ToolbarModule,
        SipPhoneModule,
        WarningsModule,
        LoadsheddingModule,
        ProfileModule,
        MainModalModule,
        MaintenanceOverlayModule,
        AgentControlModule,
        LetsDialModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: CLIENT_ID,
                    authority: AUTHORITY,
                    redirectUri: 'http://localhost:4200',
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE,
                },
            }),
            {
                interactionType: InteractionType.Popup,
                authRequest: {
                    scopes: ['user.read'],
                },
            },
            {
                interactionType: InteractionType.Popup,
                protectedResourceMap: new Map([
                    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
                ]),
            },
        ),
        BrowserAnimationsModule,
        SharedModule,
        CoreModule,
        NgxsModule.forRoot([], {
            developmentMode: !environment.production,
            selectorOptions: {
                injectContainerState: false,
                // suppressErrors: environment.production
            }
        }),
        NgxsRouterPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production
        }),
        NgxsStoragePluginModule.forRoot({
            key: 'persistent_storage_state',
        }),
        ReactiveFormsModule,
        AngularFireModule.initializeApp(FIREBASE_CONFIG, 'rain-web'),
        AngularFirestoreModule
    ],
    providers: [
        {
            provide: RouterStateSerializer,
            useClass: SnowFlakeRouterStateSerializer,
        },
        { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MainInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandlerService,
        },
        ApmErrorHandler,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        ApmService,
        DecimalPipe,
        Postfix,
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
