import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LetsDialDefaultActions } from '../actions/lets dial default actions';
import { LetsDialDefaultStateModel, LetsDialDefaultViewModel } from '../../types/default.type';

@State<LetsDialDefaultStateModel>({
  name: 'LetsDialDefaultState',
  defaults: {
    showDialler: false,
    keyPadKeys: [],
    showSettings: false
  }
})
@Injectable()
export class LetsDialDefaultState {

  @Selector()
  static showDialler(state: LetsDialDefaultStateModel) { return state.showDialler }

  @Selector()
  static showSettings(state: LetsDialDefaultStateModel) { return state.showSettings }

  @Selector()
  static keyPadKeys(state: LetsDialDefaultStateModel) { return state.keyPadKeys }

  @Selector([
    LetsDialDefaultState.keyPadKeys,
    LetsDialDefaultState.showSettings,
  ])
  static viewModel(
    keyPadKeys: string[],
    showSettings: boolean
  ): LetsDialDefaultViewModel {
    return {
      keyPadKeys,
      showSettings
    }
  }

  @Action(LetsDialDefaultActions.ShowDialler)
  ShowDialler(ctx: StateContext<LetsDialDefaultStateModel>, action: LetsDialDefaultActions.ShowDialler) {
    const { showDialler } = action;
    ctx.patchState({ showDialler });
  }

  @Action(LetsDialDefaultActions.ShowSettings)
  ShowSettings(ctx: StateContext<LetsDialDefaultStateModel>, action: LetsDialDefaultActions.ShowSettings) {
    const { showSettings } = action;
    ctx.patchState({ showSettings });
  }

  @Action(LetsDialDefaultActions.GenerateKeyPadKeys)
  GenerateKeyPadKeys(ctx: StateContext<LetsDialDefaultStateModel>, action: LetsDialDefaultActions.GenerateKeyPadKeys) {

    const keys = [];
    for (let i = 1; i < 10; i++) {
      keys.push(i.toString());
    }

    ctx.patchState({ keyPadKeys: [...keys, "*", "0", "#"] });
  }

}
