import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import LetsDial from "@letsdial/plugin"
import { PhoneStatusEnum } from "@letsdial/plugin"
import { SFValidators } from '../shared/functions/sf-validators';
import { Select, Store } from '@ngxs/store';
import { LetsDialDefaultState } from './store/states/lets-dial-defaults.state';
import { Observable } from 'rxjs';
import { LetsDialDefaultActions } from './store/actions/lets dial default actions';
import { SnowflakeSelectOption } from '../shared/elements/rain-forms/elements/snowflake-select/interfaces/rain-select-option.interface';
import { LETS_DIAL_AVAILABILITY_STATUSES, LetsDialAgentStatuses } from './constants/agent-statuses.const';
import { LetsDialDefaultViewModel } from './types/default.type';


@Component({
  selector: 'sf-lets-dial',
  templateUrl: './lets-dial.component.html',
  styleUrls: ['./lets-dial.component.scss']
})
export class LetsDialComponent implements OnInit {

  @Select(LetsDialDefaultState.viewModel) viewModel$: Observable<LetsDialDefaultViewModel>

  readonly reasonTypes: SnowflakeSelectOption[] = LETS_DIAL_AVAILABILITY_STATUSES;
  diallerForm: FormGroup
  authForm: FormGroup

  numberToDial = ''


  ringtone = new Audio('/assets/ringtone.mp3')
  authenticated: boolean


  pluginError: unknown;
  letsdial: LetsDial;
  phoneStatus: string;
  phoneError: unknown;
  callStatus: string;
  callError: unknown;
  socketStatus: string;
  userStatus: string;
  transferStatus: string;
  pop: any;
  incoming: any;
  incomingTransfer: any;
  phoneNumber: string;
  transfer: boolean;
  dialIn: boolean;
  dialInNumber: string;
  showConference: boolean;
  conferenceTimer: null;
  participants: [];
  username: string;
  password: string;
  users: [];
  queues: [];
  recording: string;

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {

    this.init()

    this.createDiallerForm()
    this.createAuthForm()
    this.letsDialOnInit()

  }

  async letsDialOnInit() {

    this.letsdial = new LetsDial(
      "https://rain.letsdial.co.za",
      {
        phoneUrl: "rain.letsdial.co.za",
        // username: "michael.belletti@rain.co.za",
        username: this.username,
        // password: "letitrain",
        password: this.password,
        organizationId: 1,
      }
    )


    this.letsdial.events.on('*', (event, data: { status: string, error: unknown }) => {

      const { status, error } = data ?? {}

      if(event === 'authorization:success'){
        this.authenticated = true
      }

      this.phoneStatus = status

      if (status === 'RINGING' || status === 'INITIATING') {
        this.ringtone.play()
      }
      if (status !== 'RINGING' && status !== 'INITIATING') {
        this.ringtone.pause()
      }

      if (error) {
        this.pluginError = error
      }


      if(event === 'phone:status'){
        this.phoneStatus = status
        this.phoneError = error ?? null
      }

      if(event === 'call:status'){
        this.callStatus = status
        if (status === 'TERMINATED') {
          clearTimeout(this.conferenceTimer)
          this.showConference = false
        }
        this.callError = error
      }

      if(event === 'socket:status'){
        this.socketStatus = status
      }

      if(event === 'user:status'){
        this.userStatus = status
      }

      if(event === 'call:pop'){
        this.pop = data
      }

      if(event === 'call:incoming'){
        this.incoming = data
        //Present Call use PresentTime for timeout
      // letsdial.answer() // to accept
      // letsdial.reject() // to reject remember to set availability after
      }

      if(event === 'transfer:rejected'){
        this.transferStatus = 'Rejected'
      }

      if(event === 'transfer:incoming'){
        this.incomingTransfer = data
      }

    })

    try {
      await this.letsdial.connect()
    } catch (err) {
      console.log({ pluginError: this.pluginError,err});

      this.pluginError = err.message
    }
  }

  init() {
    this.store.dispatch([
      new LetsDialDefaultActions.GenerateKeyPadKeys()
    ])


  }

  showSettings() {
    const showSettings = this.store.selectSnapshot(LetsDialDefaultState.showSettings)
    this.store.dispatch(new LetsDialDefaultActions.ShowSettings(!showSettings))
  }

  onOptionSelect(status: LetsDialAgentStatuses) {
    this.store.dispatch(new LetsDialDefaultActions.ShowSettings(false))

    if (status === 'Available') {
      return this.letsdial.setAvailable(true)
    }
    this.letsdial.setAvailable(false, status)

  }



  createDiallerForm() {
    this.diallerForm = this.fb.group({
      number: this.fb.control('', Validators.compose([
        Validators.required,
        SFValidators.phoneNumberValidator()
      ])
      )
    })
  }
  createAuthForm() {
    this.authForm = this.fb.group({
      username: this.fb.control(null),
      password: this.fb.control(null),
    })
  }

  onSignIn() {

    const { username, password } = this.authForm.getRawValue() ?? {}

    this.username = username
    this.password = password

    this.letsDialOnInit()

  }

  onKeyPad(key: string) {
    this.numberToDial += key
    this.diallerForm.patchValue({ number: this.numberToDial })
  }

  makeCall() {
    const { number } = this.diallerForm.getRawValue() ?? {}
    this.letsdial.call(number)
    // this.letsdial.call('0764971338') // me
  }

  endCall() {
    this.ringtone.pause()
    this.letsdial.hangup()
  }

  backspace() {
    this.numberToDial = this.numberToDial.slice(0, -1)
    this.diallerForm.patchValue({ number: this.numberToDial })
  }

}
