

export const LETS_DIAL_AVAILABILITY_STATUSES = [
  {
    label: 'Available',
    value: 'Available'
  },
  {
    label: 'Tea',
    value: 'Tea'
  },

  {
    label: 'Lunch',
    value: 'Lunch'
  },

  {
    label: 'Bathroom Break',
    value: 'Bathroom Break'
  },

  {
    label: 'Meeting',
    value: 'Meeting'
  },

  {
    label: 'Snowflake Down',
    value: 'Snowflake Down'
  }
]

export type LetsDialAgentStatuses = 'Available' |'Tea' |'Lunch' |'Bathroom Break' |'Meeting' |'Snowflake Down'
